var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-section" }, [
    _c("div", { staticClass: "login-wrapper" }, [
      _c("div", { staticClass: "login-content-wrapper" }, [
        _c("div", { staticClass: "login-content text-center" }, [
          _c("h4", [_vm._v(_vm._s(_vm.$t("DOWNLOAD.TITLE")))]),
          _c("p", { staticClass: "mt-4 mb-4" }, [
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("DOWNLOAD.INFORMATION")) +
                "\n          "
            ),
          ]),
          _c("p", { staticClass: "mt-4 mb-4" }, [
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("DOWNLOAD.INFORMATION2")) +
                "\n          "
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "btn btn-fill btn-block btn-primary",
              attrs: { href: _vm.downloadUrl },
            },
            [
              _vm._v(
                "\n              " +
                  _vm._s(_vm.$t("DOWNLOAD.BTN")) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }