<template>
  <div class="login-section">
    <div class="login-wrapper">
      <div class="login-content-wrapper">

        <div class="login-content text-center">
        
            <h4>{{ $t("DOWNLOAD.TITLE") }}</h4>

            <p class="mt-4 mb-4">
                {{ $t("DOWNLOAD.INFORMATION") }}
            </p>

            <p class="mt-4 mb-4">
                {{ $t("DOWNLOAD.INFORMATION2") }}
            </p>

              <a :href="downloadUrl" class="btn btn-fill btn-block btn-primary" >
                {{ $t("DOWNLOAD.BTN") }}
              </a>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['eventid'],
  data() {
    return {
      downloadUrl: this.user.hostname + "/mail/download/" + this.eventid
    };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  }
};
</script>
<style></style>
